<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      램 가게
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="searchText"
            @keydown.enter="goSearch"
            placeholder="상품을 검색해주세요."
          />
        </div>
        <router-link to="" @click.native="goSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_ms_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <div class="rn_ms_box_11">
        <div class="top" @click="moveMiniShop">
          <img
            :src="
              providerInfo.banner_visual
                ? providerInfo.banner_visual
                : '/data/skin/interior_comfortable_gls/images/common/mns_banner.gif'
            "
            alt=""
          />
        </div>
        <div class="sect_box">
          <div class="title">
            유의할 사항<router-link to="" @click.native="goBack"
              ><img src="@/assets/images/sub/back_ico.png" alt=""
            /></router-link>
          </div>
          <div class="info_text">
            <p>
              반품 규정의 경우, [전자상거래 등에서의 소비자보호에 관한 법률]이
              판매자가 제시한 반품 조건보다 우선합니다.
            </p>
            <br />
            <p>
              법정대리인의 동의 없이 미성년자가 물품을 구매하는 경우,
              [전자상거래 등에서의 소비자보호에 관한 법률]에 따라, 미성년자 본인
              또는 법정대리인이 구매를 취소할 수 있습니다.
            </p>
            <br />
            <p>
              전기용품, 생활용품, 어린이제품은 [전기용품 및 생활용품 안전관리법]
              및 [어린이제품 안전 특별법]이 규정하는 안전관리대상 품목입니다.
              해당 제품을 구매하실 경우 해당 제품이 안전인증, 안전확인, 공급자
              적합성확인, 안전기준준수 적용 제품인지 확인하시기 바랍니다.
            </p>
            <br />
            <p>
              소비자가 리뷰앤메이크머니가 제공하는 결제시스템을 사용하지 않고
              판매자와 직접 거래하실 경우, 소비자는 상품을 받지 못하는 등의
              불이익을 당할 수 있습니다. 유의하시기 바랍니다. 직거래에 의한
              소비자 피해에 대해 당사는 일절 책임지지 않습니다. 직거래 유도
              유형은 대략 다음과 같습니다.
            </p>
            <p class="none">1. 재고 확인을 위해 먼저 연락해 달라 유인</p>
            <p class="none">2. 할인을 해 주겠다며 별도 계좌로 입금 요구</p>
            <p class="none">3. 안전결제라며 가짜 URL을 통해 결제 유도</p>
            <p class="none">4. 이메일, SNS, 전화 등을 통해 직거래 유도</p>
            <br />
            <p>
              리뷰앤메이크머니(주)는 통신판매의 당사자가 아닌 중개자입니다.
              상품정보, 거래조건, 주문, 배송, 교환및 환불 등과 관련한 의무와
              책임은 통신판매의 당사자인 각 판매자에게 있습니다.
            </p>
          </div>
          <!-- <div class="dec_box">
            <div class="box">
              <h4>신고센터</h4>
              <p>
                리뷰앤메이크머니(주)는 소비자 보호와 본 사이트의 안전거래를 위해
                신고센터를 운영하고 있습니다. 안전거래를 저해하는 행위에 대해
                아래 버튼을 눌러 신고해 주시기 바랍니다.
              </p>
            </div>
            <div class="box">
              <h4>
                신고하기<a href="#">∨∧</a>
              </h4>
              <p>아래에서 신고할 내용을 선택해주세요.</p>
              <ul>
                <li>
                  <label
                    ><input type="radio" name="" />상품명이
                    잘못되었습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input type="radio" name="" />카테고리 경로가
                    잘못되었습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input type="radio" name="" />불법물, 음란물이 포함되어
                    있습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input type="radio" name="" />직거래를 유도하는
                    내용입니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input type="radio" name="" />불법 유통되는
                    상품입니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input type="radio" name="" />기타(아래에 내용을
                    적어주세요.)</label
                  >
                </li>
              </ul>
              <textarea></textarea>
              <div class="btn">
                <a href="#" class="white">취소</a>
                <a href="#" class="gray">신고</a>
              </div>
            </div> 
          </div>-->
        </div>
        <div class="rn_info">
          주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌
          통신판매중개자입니다. 상품, 상품정보, 거래에 관한 의무와 책임은
          통신판매 당사자인 판매자에게 있습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
export default {
  data() {
    return {
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      providerId: this.$route.params.provider_id || null,
      categoryId: this.$route.query.category_id || null,
      goodsSeq: this.$route.query.goods_seq || null,
      searchText: "",
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", ["result", "msg", "providerInfo"]),
  },
  methods: {
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    moveMiniShop() {
      this.$router.push(`/mini_shop/${this.providerId}`);
    },
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/goods_search?search=${this.searchText}`);
    },
    goBack() {
      const url = `/market_goods_detail/${this.categoryId}/${this.goodsSeq}`;
      this.$router.push(url);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      await this.$store.dispatch("shop/getSellerInfo", {
        provider_seq: this.providerId,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
    },
    getMenu(menu) {},
  },
  components: {
    MainMenu,
  },
};
</script>

<style></style>
